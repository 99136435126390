export default [
    {
        path: 'matricula',
        name: 'MatriculaList',
        meta: {title: "Matrículas"},
        component: () => import('@pages/Matricula/Matricula.vue')
    },
    {
        path: 'matricula/create',
        props: true,
        name: 'MatriculaCreate',
        meta: {title: "Cadastro de Matrícula"},
        component: () => import('@pages/Matricula/MatriculaCreate.vue')
    },
    {
        path: 'matricula/edit/:id',
        props: true,
        name: 'MatriculaEdit',
        meta: {title: "Edição de Matrícula"},
        component: () => import('@pages/Matricula/MatriculaEdit.vue')
    },
    {
        path: 'matricula/show/:id',
        props: true,
        name: 'MatriculaShow',
        meta: {title: "Ficha de Matrícula"},
        component: () => import('@pages/Matricula/MatriculaShow.vue')
    },
    {
        path: 'nota',
        name: 'NotaList',
        meta: {title: "Notas"},
        component: () => import('@pages/Nota/Nota.vue')
    },
    {
        path: 'nota/edit/:id',
        props: true,
        name: 'NotaEdit',
        meta: {title: "Edição de Notas"},
        component: () => import('@pages/Nota/NotaEdit.vue')
    },
    {
        path: 'disciplina',
        name: 'DisciplinaList',
        meta: {title: "Disciplinas"},
        component: () => import('@pages/Disciplina/Disciplina.vue')
    },
    {
        path: 'disciplina/create',
        name: 'DisciplinaCreate',
        meta: {title: "Cadastro de Disciplina"},
        component: () => import('@pages/Disciplina/DisciplinaCreate.vue')
    },
    {
        path: 'disciplina/edit/:id',
        props: true,
        name: 'DisciplinaEdit',
        meta: {title: "Edição de Disciplina"},
        component: () => import('@pages/Disciplina/DisciplinaEdit.vue')
    },
    {
        path: 'modalidade',
        name: 'ModalidadeList',
        meta: {title: "Modalidades"},
        component: () => import('@pages/Modalidade/Modalidade.vue')
    },
    {
        path: 'modalidade/create',
        name: 'ModalidadeCreate',
        meta: {title: "Cadastro de Modalidade"},
        component: () => import('@pages/Modalidade/ModalidadeCreate.vue')
    },
    {
        path: 'modalidade/edit/:id',
        props: true,
        name: 'ModalidadeEdit',
        meta: {title: "Edição de Modalidade"},
        component: () => import('@pages/Modalidade/ModalidadeEdit.vue')
    },

    {
        path: 'curso',
        name: 'CursoList',
        meta: {title: "Cursos"},
        component: () => import('@pages/Curso/Curso.vue')
    },
    {
        path: 'curso/show/:id',
        props: true,
        name: 'CursoShow',
        meta: {title: "Curso"},
        component: () => import('@pages/Curso/CursoShow.vue')
    },
    {
        path: 'curso/create',
        name: 'CursoCreate',
        meta: {title: "Cadastro de Curso"},
        component: () => import('@pages/Curso/CursoCreate.vue')
    },
    {
        path: 'curso/edit/:id',
        props: true,
        name: 'CursoEdit',
        meta: {title: "Edição de Curso"},
        component: () => import('@pages/Curso/CursoEdit.vue')
    },

    {
        path: 'periodo/:id',
        props: true,
        name: 'PeriodoShow',
        meta: {title: "Visualizar Período"},
        component: () => import('@pages/Periodo/PeriodoShow.vue')
    },

    {
        path: 'turma',
        name: 'TurmaList',
        meta: {title: "Turmas"},
        component: () => import('@pages/Turma/Turma.vue')
    },
    {
        path: 'turma/create/:periodo_id',
        props: true,
        name: 'TurmaCreate',
        meta: {title: "Cadastro de Turma"},
        component: () => import('@pages/Turma/TurmaCreate.vue')
    },
    {
        path: 'turma/edit/:id',
        props: true,
        name: 'TurmaEdit',
        meta: {title: "Edição de Turma"},
        component: () => import('@pages/Turma/TurmaEdit.vue')
    },

    {
        path: 'turma/horario/:turma_id/:periodo_id',
        props: true,
        name: 'TurmaHorario',
        meta: {title: "Adicionar Professor/Horários"},
        component: () => import('@pages/Turma/TurmaHorario.vue')
    },

    {
        path: 'disciplina/horario/:id',
        props: true,
        name: 'DisciplinaHorario',
        meta: {title: "Adicionar Professor/Horários"},
        component: () => import('@pages/CursoTecnico/AdicionarHorarioCurso.vue')
    },

    {
        path: 'periodo-letivo',
        name: 'PeriodoLetivoList',
        meta: {title: "Período Letivo"},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivo.vue')
    },
    {
        path: 'periodo-letivo/create',
        name: 'PeriodoLetivoCreate',
        meta: {title: "Cadastro de Período Letivo"},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoCreate.vue')
    },
    {
        path: 'periodo-letivo/edit/:id',
        props: true,
        name: 'PeriodoLetivoEdit',
        meta: {title: "Edição de Período Letivo"},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoEdit.vue')
    },
    {
        path: 'matricula/online',
        name: 'Configuracao',
        meta: {title: "Matrícula Online"},
        component: () => import('@pages/MatriculaOnline/Configuracao.vue')
    },
    {
        path: 'relatorio/matricula',
        name: 'RelatorioMatricula',
        meta: {title: "Relatório de Matrícula"},
        component: () => import('@pages/Relatorio/Matricula.vue')
    },
    {
        path: 'relatorio/frequencia',
        name: 'RelatorioFrequencia',
        meta: {title: "Relatório de Frequência de Aula"},
        component: () => import('@pages/Relatorio/Frequencia.vue')
    },
    {
        path: 'relatorio/alunos',
        name: 'RelatorioAluno',
        meta: {title: "Relatório de Alunos"},
        component: () => import('@pages/Relatorio/Aluno.vue')
    },
    {
        path: 'relatorio/registro/detalhes/:id',
        props: true,
        name: 'RegistrosDetalhes',
        meta: {title: "Relatório de Registros"},
        component: () => import('@pages/Professor/RegistrosDetalhes.vue')
    },
    {
        path: 'relatorio/registro',
        name: 'RelatorioRegistro',
        meta: {title: "Relatório de Registros"},
        component: () => import('@pages/Relatorio/Registro.vue')
    },
    {
        path: 'relatorio/falta',
        name: 'RelatorioFalta',
        meta: {title: "Relatório de Faltas"},
        component: () => import('@pages/Relatorio/Faltas.vue')
    },
    {
        path: 'relatorio/sala',
        name: 'RelatorioSala',
        meta: {title: "Relatório de Salas"},
        component: () => import('@pages/Relatorio/Salas.vue')
    },
    {
        path: 'relatorio/falta/detalhes/:id',
        props: true,
        name: 'FaltasDetalhes',
        meta: {title: "Relatório de Faltas"},
        component: () => import('@pages/Professor/FaltasDetalhes.vue')
    },
    {
        path: 'registro-aula',
        name: 'RegistroList',
        meta: {title: "Registro de Aula"},
        component: () => import('@pages/Registro/RegistroList.vue')
    },
    {
        path: 'registro-aula/show/:id',
        props: true,
        name: 'RegistroShow',
        meta: {title: "Visualizar Registro de Aula"},
        component: () => import('@pages/Registro/RegistroShow.vue')
    },
    {
        path: 'registro-aula/create',
        name: 'RegistroCreateAdm',
        meta: {title: "Cadastrar Registro de Aula"},
        component: () => import('@pages/Registro/RegistroCreate.vue')
    },
    {
        path: 'registro-aula/edit/:id',
        props: true,
        name: 'RegistroEditAdm',
        meta: {title: "Editar Registro de Aula"},
        component: () => import('@pages/Registro/RegistroEdit.vue')
    },
    {
        path: 'registro-aula/configuracao',
        name: 'ConfiguracaoRegistro',
        meta: {title: "Configurações de Registro de Aula"},
        component: () => import('@pages/Registro/ConfiguracaoRegistro.vue')
    },
    {
        path: 'frequencia',
        name: 'FrequenciaList',
        meta: {title: "Frequência"},
        component: () => import('@pages/Frequencia/FrequenciaList.vue')
    },
    {
        path: 'frequencia/editar/:matricula_id',
        props: true,
        name: 'FrequenciaEdit',
        meta: {title: "Cadastrar/Editar Frequência"},
        component: () => import('@pages/Frequencia/FrequenciaEdit.vue')
    },
    {
        path: 'frequencia/justificar',
        props: true,
        name: 'FrequenciaJustificar',
        meta: {title: "Justificar Faltas"},
        component: () => import('@pages/Frequencia/FrequenciaJustificar.vue')
    },
    {
        path: 'configuracao/perfil-acesso',
        name: 'PerfilAcesso',
        meta: {title: "Perfil de Acesso"},
        component: () => import('@pages/PerfilAcesso/PerfilAcessoList.vue')
    },
    {
        path: 'configuracao/perfil-acesso/create',
        name: 'PerfilAcessoCreate',
        meta: {title: "Cadastro de Perfil de Acesso"},
        component: () => import('@pages/PerfilAcesso/PerfilAcessoCreate.vue')
    },
    {
        path: 'configuracao/perfil-acesso/edit',
        name: 'PerfilAcessoEdit',
        meta: {title: "Editar Perfil de Acesso"},
        component: () => import('@pages/PerfilAcesso/PerfilAcessoEdit.vue')
    },
    {
        path: 'configuracao/perfil-acesso/permissoes',
        name: 'PerfilAcessoPermissoes',
        meta: {title: "Permissões de Perfil de Acesso"},
        component: () => import('@pages/PerfilAcesso/PerfilAcessoPermissoes.vue')
    },
    {
        path: 'configuracao/log-usuario',
        name: 'LogUsuario',
        meta: {title: "Log de Usuário"},
        component: () => import('@pages/Configuracao/LogUsuario.vue')
    },
    {
        path: 'registro_aula_tecnico',
        name: 'RegistroAulaTecnicoList',
        meta: {title: "Registro Técnicos"},
        component: () => import('@pages/RegistroTecnico/RegistroAulaTecnicoList.vue')
    },
    {
        path: 'registro_aula_tecnico/create',
        props: true,
        name: 'RegistroAulaTecnicoCreate',
        meta: {title: "Cadastro de Registro"},
        component: () => import('@pages/RegistroTecnico/RegistroAulaTecnicoCreate.vue')
    },
    {
        path: 'registro_aula_tecnico/edit/:id',
        props: true,
        name: 'RegistroAulaTecnicoEdit',
        meta: {title: "Edição de Registro de Aulas"},
        component: () => import('@pages/RegistroTecnico/RegistroAulaTecnicoEdit.vue')
    },
    {
        path: 'registro_aula_tecnico/edit/:id',
        props: true,
        name: 'RegistroAulaTecnicoEdit',
        meta: {title: "Edição de Registro de Aulas"},
        component: () => import('@pages/RegistroTecnico/RegistroAulaTecnicoEdit.vue')
    },
    {
        path: 'adicionar_justificativa/:registro_aula_tecnico_id',
        props: true,
        name: 'AdicionarJustificativaTecnico',
        meta: {title: "Adicionar Justificativa"},
        component: () => import('@pages/RegistroTecnico/AdicionarJustificativa.vue')
    },
    {
        path: 'curso-tecnico',
        name: 'CursoTecnicoList',
        meta: {title: "Cursos Técnicos"},
        component: () => import('@pages/CursoTecnico/CursoTecnico.vue')
    },
    {
        path: 'curso-tecnico/show/:id',
        props: true,
        name: 'CursoTecnicoShow',
        meta: {title: "Visualização de Curso Técnico"},
        component: () => import('@pages/CursoTecnico/CursoTecnicoShow.vue')
    },
    {
        path: 'curso-tecnico/create',
        name: 'CursoTecnicoCreate',
        meta: {title: "Cadastro de Curso Técnico"},
        component: () => import('@pages/CursoTecnico/CursoTecnicoCreate.vue')
    },
    {
        path: 'curso-tecnico/edit/:id',
        props: true,
        name: 'CursoTecnicoEdit',
        meta: {title: "Edição de Curso Técnico"},
        component: () => import('@pages/CursoTecnico/CursoTecnicoEdit.vue')
    },
    {
        path: 'disciplina/create/:periodo_id',
        props: true,
        name: 'DisciplinaTecnicoCreate',
        meta: {title: "Cadastro de Disciplina em Curso Técnico"},
        component: () => import('@pages/PeriodoTecnico/DisciplinaCreate.vue')
    },
    {
        path: 'matricula-tecnico/diario',
        name: 'DiarioClasseTecnico',
        meta: {title: "Matrículas"},
        component: () => import('@pages/MatriculaTecnico/DiarioClasseTecnico.vue')
    },
    {
        path: 'matricula-tecnico',
        name: 'MatriculaTecnicoList',
        meta: {title: "Matrículas"},
        component: () => import('@pages/MatriculaTecnico/MatriculaTecnico.vue')
    },
    {
        path: 'matricula-tecnico/create',
        name: 'MatriculaTecnicoCreate',
        meta: {title: "Cadastro de Matrícula de Curso Técnico"},
        component: () => import('@pages/MatriculaTecnico/MatriculaTecnicoCreate.vue')
    },
    {
        path: 'matricula-tecnico/edit/:id',
        props: true,
        name: 'MatriculaTecnicoEdit',
        meta: {title: "Edição de Matrícula de Curso Técnico"},
        component: () => import('@pages/MatriculaTecnico/MatriculaTecnicoEdit.vue')
    },
    {
        path: 'matricula-tecnico/show/:id',
        props: true,
        name: 'MatriculaTecnicoShow',
        meta: {title: "Ficha de Matrícula de Curso Técnico"},
        component: () => import('@pages/MatriculaTecnico/MatriculaTecnicoShow.vue')
    },
    {
        path: 'turma/link/:id',
        props: true,
        name: 'TurmaLink',
        meta: {title: "Links da Turma"},
        component: () => import('@pages/Turma/TurmaLink.vue')
    },
    {
        path: 'turma/link/create/:id',
        props: true,
        name: 'TurmaLinkCreate',
        meta: {title: "Links da Turma"},
        component: () => import('@pages/Turma/TurmaLinkCreate.vue')
    },
    {
        path: 'periodo/link/:id',
        props: true,
        name: 'PeriodoLink',
        meta: {title: "Links do Período"},
        component: () => import('@pages/Periodo/PeriodoLink.vue')
    },
    {
        path: 'periodo/link/create/:id',
        props: true,
        name: 'PeriodoLinkCreate',
        meta: {title: "Links do Período"},
        component: () => import('@pages/Periodo/PeriodoLinkCreate.vue')
    },
    {
        path: 'relatorio/diario',
        name: 'RelatorioDiario',
        meta: {title: "Relatório de Diário de Classe do Semestre"},
        component: () => import('@pages/Relatorio/DiarioClasse.vue')
    },
]