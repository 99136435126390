import Vue from 'vue'
import Vuex from 'vuex'
import staticData from '@api/staticData'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        isLogged: false,
        user: null,
        modalPix: false,
        alunoSequenciaOnlineForm: {
            cpf: null,
            telefone: null,
        },
        listaBanco: [],
        listaEstados: [],
        listaEstadoCivil: [],
        listaGrauInstrucao: [],
        listaGrauParentesco: [],
        listaNacionalidade: [],
        listaOrgaoEmissor: [],
        listaRacaCor: [],
        listaReligiao: [],
        listaEnvioComunicado: [],
        listaEnvioComunicadoProfessor: [],
        optionsMatricula: {},
        optionsFrequencia: {},
        //created
        optionsCadastroAluno: {
            itemsPerPage: 25,
            search: '',
            awaitingSearch: false,
            columns: ['aluno.id', 'entidade.nome']
        },
        optionsCadastroProfessor: {
            itemsPerPage: 25,
            search: '',
            ativo: 1,
            sortBy: ['ativo'],
            sortDesc: ['true'],
        },
        optionsCadastroFuncionario: {
            itemsPerPage: 25,
            search: '',
            ativo: 1,
            sortBy: ['ativo'],
            sortDesc: ['true']
        },
        optionsCadastroFornecedor: {
            itemsPerPage: 25,
            search: '',
        },
        optionsSorteio: {
            tipo_vaga: null,
            curso: null,
            turma: null,
            itemsPerPage: 25,
        },
        optionsCadastroUnidade: {
            itemsPerPage: 25,
            search: '',
        },
        optionsCadastroCargo: {
            itemsPerPage: 25,
            search: '',
        },
        optionsCadastroModeloOcorrencia: {
            itemsPerPage: 25,
            search: '',
        },
        optionsCadastroModeloDocumento: {
            itemsPerPage: 25,
            search: '',
        },
        optionsAdministrativoBolsa: {
            itemsPerPage: 25,
            search: '',
            awaitingSearch: false,
        },
        optionsAdministrativoSala: {
            itemsPerPage: 25,
            search: '',
        },
        optionsAdministrativoModalidade: {
            itemsPerPage: 25,
            search: '',
        },
        optionsAdministrativoCurso: {
            itemsPerPage: 25,
            search: '',
            columns: [
                'curso.id',
                'curso.nome',
                'modalidade.nome as modalidade_nome',
                'curso.ativo'
            ],
            with: ['modalidade'],
        },
        optionsAdministrativoPeriodoLetivo: {
            itemsPerPage: 25,
            search: '',
        },
        optionsPatrimonioTipo: {
            itemsPerPage: 25,
            search: '',
        },
        optionsPatrimonioItem: {
            itemsPerPage: 25,
            search: '',
        },
        optionsComunicacaoComunicado: {
            itemsPerPage: 25,
            search: '',
        },
        optionsComunicacaoEvento: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroCategoria: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroConta: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroFormaPagamento: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroConvenio: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroContaReceber: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroContaPagar: {
            itemsPerPage: 25,
            search: '',
        },
        optionsFinanceiroLancamento: {
            itemsPerPage: 25,
            search: '',
        },
        optionsTecnicoCurso: {
            itemsPerPage: 25,
            search: '',
            columns: [
                'curso.id',
                'curso.nome',
                'modalidade.nome as modalidade_nome',
                'curso.ativo'
            ],
            with: ['modalidade'],
            onlyTecnico: true,
        },
        optionsTecnicoDisciplina: {
            itemsPerPage: 25,
            search: '',
        },
        optionsTecnicoMatricula: {
            itemsPerPage: 25,
            search: '',
        },
        optionsTecnicoRegistro: {
            itemsPerPage: 25,
            search: '',
        },
        optionsAcademicoMatricula: {
            itemsPerPage: 25,
            search: '',
            sortBy: ['created_at'],
            sortDesc: [true],
        },
        optionsAcademicoRegistro: {
            itemsPerPage: 25,
            search: '',
            awaitingSearch: false,
            with: [
                'professorhorario.turma.matriculasativas.aluno.entidade',
                'professorhorario.turma.periodo.curso',
                'professorhorario.professor.entidade',
            ],
            searchBy: 'aluno',
        },
        optionsAcademicoFrequencia: {
        },
        optionsAcademicoNota: {
            itemsPerPage: 25,
            search: "",
            ativo: 1,
            modalidade_id: null,
            with: ['periodo.curso'],
        },
        optionsRelatorioMatricula: {
            itemsPerPage: '-1'
        },
        optionsRelatorioFrequencia: {
            periodo_letivo_id: '',
            modalidade_id: '',
            curso_id: '',
            turma_id: '',
            periodo_id: '',
            mes: 1,
            itemsPerPage: -1,
            with: [
                'matricula.aluno.entidade',
                'registroaula', 
            ]
        },
        optionsRelatorioAluno: {
            itemsPerPage: '-1'
        },
        optionsRelatorioSalas: {
            itemsPerPage: -1,
            disponivel: true,
            periodoAtivo: true,
        },
        optionsConfiguracaoPerfil: {
            itemsPerPage: 25,
            search: '',
        },
        optionsConfiguracaoLog: {
            itemsPerPage: 25,
            search: '',
        },
        optionsSiteBanner: {
            itemsPerPage: 25,
            search: '',
        },
        optionsSiteNoticia: {
            itemsPerPage: 25,
            search: '',
        },
        optionsSiteModalidade: {
            itemsPerPage: 25,
            search: '',
        },
        optionsSiteCurso: {
            itemsPerPage: 25,
            search: '',
        },
        optionsSiteDepoimento: {
            itemsPerPage: 25,
            search: '',
        },

    },
    getters: {
        getModalPix(state) {
            return state.modalPix;
        },
    },
    mutations: {
        resetState: (state) => {
            Object.assign(state, {
                isLogged: false,
                user: null,
                modalPix: false,
                alunoSequenciaOnlineForm: {
                    cpf: null,
                    telefone: null,
                },
                listaBanco: [],
                listaEstados: [],
                listaEstadoCivil: [],
                listaGrauInstrucao: [],
                listaGrauParentesco: [],
                listaNacionalidade: [],
                listaOrgaoEmissor: [],
                listaRacaCor: [],
                listaReligiao: [],
                listaEnvioComunicado: [],
                listaEnvioComunicadoProfessor: [],
                optionsMatricula: {},
                optionsFrequencia: {},
                //created
                optionsCadastroAluno: {
                    itemsPerPage: 25,
                    search: '',
                    awaitingSearch: false,
                    columns: ['aluno.id', 'entidade.nome']
                },
                optionsCadastroProfessor: {
                    itemsPerPage: 25,
                    search: '',
                    ativo: 1,
                    sortBy: ['ativo'],
                    sortDesc: ['true'],
                },
                optionsCadastroFuncionario: {
                    itemsPerPage: 25,
                    search: '',
                    ativo: 1,
                    sortBy: ['ativo'],
                    sortDesc: ['true']
                },
                optionsCadastroFornecedor: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsSorteio: {
                    tipo_vaga: null,
                    curso: null,
                    turma: null,
                    itemsPerPage: 25,
                },
                optionsCadastroUnidade: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsCadastroCargo: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsCadastroModeloOcorrencia: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsCadastroModeloDocumento: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsAdministrativoBolsa: {
                    itemsPerPage: 25,
                    search: '',
                    awaitingSearch: false,
                },
                optionsAdministrativoSala: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsAdministrativoModalidade: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsAdministrativoCurso: {
                    itemsPerPage: 25,
                    search: '',
                    columns: [
                        'curso.id',
                        'curso.nome',
                        'modalidade.nome as modalidade_nome',
                        'curso.ativo'
                    ],
                    with: ['modalidade'],
                },
                optionsAdministrativoPeriodoLetivo: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsPatrimonioTipo: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsPatrimonioItem: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsComunicacaoComunicado: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsComunicacaoEvento: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroCategoria: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroConta: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroFormaPagamento: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroConvenio: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroContaReceber: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroContaPagar: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsFinanceiroLancamento: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsTecnicoCurso: {
                    itemsPerPage: 25,
                    search: '',
                    columns: [
                        'curso.id',
                        'curso.nome',
                        'modalidade.nome as modalidade_nome',
                        'curso.ativo'
                    ],
                    with: ['modalidade'],
                    onlyTecnico: true,
                },
                optionsTecnicoDisciplina: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsTecnicoMatricula: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsTecnicoRegistro: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsAcademicoMatricula: {
                    itemsPerPage: 25,
                    search: '',
                    sortBy: ['created_at'],
                    sortDesc: [true],
                },
                optionsAcademicoRegistro: {
                    itemsPerPage: 25,
                    search: '',
                    awaitingSearch: false,
                    with: [
                        'professorhorario.turma.matriculasativas.aluno.entidade',
                        'professorhorario.turma.periodo.curso',
                        'professorhorario.professor.entidade',
                    ],
                    searchBy: 'aluno',
                },
                optionsAcademicoFrequencia: {
                },
                optionsAcademicoNota: {
                    itemsPerPage: 25,
                    search: "",
                    ativo: 1,
                    modalidade_id: null,
                    with: ['periodo.curso'],
                },
                optionsRelatorioMatricula: {
                    itemsPerPage: '-1'
                },
                optionsRelatorioFrequencia: {
                    periodo_letivo_id: '',
                    modalidade_id: '',
                    curso_id: '',
                    turma_id: '',
                    periodo_id: '',
                    mes: 1,
                    itemsPerPage: -1,
                    with: [
                        'matricula.aluno.entidade',
                        'registroaula', 
                    ]
                },
                optionsRelatorioAluno: {
                    itemsPerPage: '-1'
                },
                optionsRelatorioSalas: {
                    itemsPerPage: -1,
                    disponivel: true,
                    periodoAtivo: true,
                },
                optionsConfiguracaoPerfil: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsConfiguracaoLog: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsSiteBanner: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsSiteNoticia: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsSiteModalidade: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsSiteCurso: {
                    itemsPerPage: 25,
                    search: '',
                },
                optionsSiteDepoimento: {
                    itemsPerPage: 25,
                    search: '',
                },
            });
        },
        isLogged(state, payload) {
            state.isLogged = payload
        },
        setAlunoSequenciaOnlineForm(state, form) {
            if (form) {
                Object.keys(form).forEach(key => {
                    state.alunoSequenciaOnlineForm[key] = form[key];
                });
                return;
            }
            state.alunoSequenciaOnlineForm = {};
        },
        setUser(state, payload) {
            state.user = payload
        },
        setAluno(state, payload) {
            state.user.entidade.aluno = payload
        },
        setListaBanco(state, payload) {
            state.listaBanco = payload
        },
        setListaEstados(state, payload) {
            state.listaEstados = payload
        },
        setListaEstadoCivil(state, payload) {
            state.listaEstadoCivil = payload
        },
        setListaGrauInstrucao(state, payload) {
            state.listaGrauInstrucao = payload
        },
        setListaGrauParentesco(state, payload) {
            state.listaGrauParentesco = payload
        },
        setListaNacionalidade(state, payload) {
            state.listaNacionalidade = payload
        },
        setListaOrgaoEmissor(state, payload) {
            state.listaOrgaoEmissor = payload
        },
        setListaRacaCor(state, payload) {
            state.listaRacaCor = payload
        },
        setListaReligiao(state, payload) {
            state.listaReligiao = payload
        },
        setListaEnvioComunicado(state, payload) {
            state.listaEnvioComunicado = payload
        },
        setListaEnvioComunicadoProfessor(state, payload) {
            state.listaEnvioComunicadoProfessor = payload
        },
        setOptionsMatricula(state, payload) {
            state.optionsMatricula = payload
        },
        setOptionsFrequencia(state, payload) {
            state.optionsFrequencia = payload
        },
        setModalPix(state, payload) {
            state.modalPix = payload
        },
        //watch
        setOptionsCadastroAluno(state, payload) {
            state.optionsCadastroAluno = payload
        },
        setOptionsCadastroProfessor(state, payload) {
            state.optionsCadastroProfessor = payload
        },
        setOptionsCadastroFuncionario(state, payload) {
            state.optionsCadastroFuncionario = payload
        },
        setOptionsCadastroFornecedor(state, payload) {
            state.optionsCadastroFornecedor = payload
        },
        setOptionsSorteio(state, payload) {
            state.optionsSorteio = payload
        },
        setOptionsCadastroUnidade(state, payload) {
            state.optionsCadastroUnidade = payload
        },
        setOptionsCadastroCargo(state, payload) {
            state.optionsCadastroCargo = payload
        },
        setOptionsCadastroModeloOcorrencia(state, payload) {
            state.optionsCadastroModeloOcorrencia = payload
        },
        setOptionsCadastroModeloDocumento(state, payload) {
            state.optionsCadastroModeloDocumento = payload
        },
        setOptionsAdministrativoBolsa(state, payload) {
            state.optionsAdministrativoBolsa = payload
        },
        setOptionsAdministrativoSala(state, payload) {
            state.optionsAdministrativoSala = payload
        },
        setOptionsAdministrativoModalidade(state, payload) {
            state.optionsAdministrativoModalidade = payload
        },
        setOptionsAdministrativoCurso(state, payload) {
            state.optionsAdministrativoCurso = payload
        },
        setOptionsAdministrativoPeriodoLetivo(state, payload) {
            state.optionsAdministrativoPeriodoLetivo = payload
        },
        setOptionsPatrimonioTipo(state, payload) {
            state.optionsPatrimonioTipo = payload
        },
        setOptionsPatrimonioItem(state, payload) {
            state.optionsPatrimonioItem = payload
        },
        setOptionsComunicacaoComunicado(state, payload) {
            state.optionsComunicacaoComunicado = payload
        },
        setOptionsComunicacaoEvento(state, payload) {
            state.optionsComunicacaoEvento = payload
        },
        setOptionsFinanceiroCategoria(state, payload) {
            state.optionsFinanceiroCategoria = payload
        },
        setOptionsFinanceiroConta(state, payload) {
            state.optionsFinanceiroConta = payload
        },
        setOptionsFinanceiroFormaPagamento(state, payload) {
            state.optionsFinanceiroFormaPagamento = payload
        },
        setOptionsFinanceiroConvenio(state, payload) {
            state.optionsFinanceiroConvenio = payload
        },
        setOptionsFinanceiroContaReceber(state, payload) {
            state.optionsFinanceiroContaReceber = payload
        },
        setOptionsFinanceiroContaPagar(state, payload) {
            state.optionsFinanceiroContaPagar = payload
        },
        setOptionsFinanceiroLancamento(state, payload) {
            state.optionsFinanceiroLancamento = payload
        },
        setOptionsTecnicoCurso(state, payload) {
            state.optionsTecnicoCurso = payload
        },
        setOptionsTecnicoDisciplina(state, payload) {
            state.optionsTecnicoDisciplina = payload
        },
        setOptionsTecnicoMatricula(state, payload) {
            state.optionsTecnicoMatricula = payload
        },
        setOptionsTecnicoRegistro(state, payload) {
            state.optionsTecnicoRegistro = payload
        },
        setOptionsAcademicoMatricula(state, payload) {
            state.optionsAcademicoMatricula = payload
        },
        setOptionsAcademicoRegistro(state, payload) {
            state.optionsAcademicoRegistro = payload
        },
        setOptionsAcademicoFrequencia(state, payload) {
            state.optionsAcademicoFrequencia = payload
        },
        setOptionsAcademicoNota(state, payload) {
            state.optionsAcademicoNota = payload
        },
        setOptionsRelatorioMatricula(state, payload) {
            state.optionsRelatorioMatricula = payload
        },
        setOptionsRelatorioFrequencia(state, payload) {
            state.optionsRelatorioFrequencia = payload
        },
        setOptionsRelatorioAluno(state, payload) {
            state.optionsRelatorioAluno = payload
        },
        setOptionsRelatorioSalas(state, payload) {
            state.optionsRelatorioSalas = payload
        },
        setOptionsConfiguracaoPerfil(state, payload) {
            state.optionsConfiguracaoPerfil = payload
        },
        setOptionsConfiguracaoLog(state, payload) {
            state.optionsConfiguracaoLog = payload
        },
        setOptionsSiteBanner(state, payload) {
            state.optionsSiteBanner = payload
        },
        setOptionsSiteNoticia(state, payload) {
            state.optionsSiteNoticia = payload
        },
        setOptionsSiteModalidade(state, payload) {
            state.optionsSiteModalidade = payload
        },
        setOptionsSiteCurso(state, payload) {
            state.optionsSiteCurso = payload
        },
        setOptionsSiteDepoimento(state, payload) {
            state.optionsSiteDepoimento = payload
        },



    },
    actions: {
        async loadStaticData({ commit }) {
            const listaStaticData = await staticData.getAll()

            commit('setListaBanco', listaStaticData.data.banco)
            commit('setListaEstadoCivil', listaStaticData.data.estadoCivil)
            commit('setListaEstados', listaStaticData.data.estados)
            commit('setListaGrauInstrucao', listaStaticData.data.grauInstrucao)
            commit('setListaGrauParentesco', listaStaticData.data.grauParentesco)
            commit('setListaNacionalidade', listaStaticData.data.nacionalidade)
            commit('setListaOrgaoEmissor', listaStaticData.data.orgaoEmissor)
            commit('setListaRacaCor', listaStaticData.data.racaCor)
            commit('setListaReligiao', listaStaticData.data.religiao)
        },

        updateAlunoSequenciaOnlineForm({ commit }, payload) {
            commit('setAlunoSequenciaOnlineForm', payload);
        },
    },
})

export default store
