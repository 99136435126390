export default [
    {
        path: 'cargo',
        name: 'CargoList',
        meta: {title: "Cargos"},
        component: () => import('@pages/Cargo/Cargo.vue')
    },
    {
        path: 'cargo/create',
        name: 'CargoCreate',
        meta: {title: "Cadastro de Cargo"},
        component: () => import('@pages/Cargo/CargoCreate.vue')
    },
    {
        path: 'cargo/edit/:id',
        props: true,
        name: 'CargoEdit',
        meta: {title: "Edição de Cargo"},
        component: () => import('@pages/Cargo/CargoEdit.vue')
    },
    {
        path: 'documento',
        name: 'DocumentoList',
        meta: {title: "Documentos"},
        component: () => import('@pages/Documento/Documento.vue')
    },
    {
        path: 'documento/create',
        name: 'DocumentoCreate',
        meta: {title: "Cadastro de Documento"},
        component: () => import('@pages/Documento/DocumentoCreate.vue')
    },
    {
        path: 'documento/edit/:id',
        props: true,
        name: 'DocumentoEdit',
        meta: {title: "Edição de Documento"},
        component: () => import('@pages/Documento/DocumentoEdit.vue')
    },
    {
        path: 'ocorrencia-tipo',
        name: 'OcorrenciaTipoList',
        meta: {title: "Modelo de Ocorrência"},
        component: () => import('@pages/OcorrenciaTipo/OcorrenciaTipo.vue')
    },
    {
        path: 'ocorrencia-tipo/create',
        name: 'OcorrenciaTipoCreate',
        meta: {title: "Cadastro de Modelo de Ocorrência"},
        component: () => import('@pages/OcorrenciaTipo/OcorrenciaTipoCreate.vue')
    },
    {
        path: 'ocorrencia-tipo/edit/:id',
        props: true,
        name: 'OcorrenciaTipoEdit',
        meta: {title: "Edição de Modelo de Ocorrência"},
        component: () => import('@pages/OcorrenciaTipo/OcorrenciaTipoEdit.vue')
    },
    {
        path: 'ocorrencia/create',
        props: true,
        name: 'OcorrenciaCreate',
        meta: {title: "Registro de Ocorrência"},
        component: () => import('@pages/AlunoOcorrencia/OcorrenciaCreate.vue')
    },
    {
        path: 'ocorrencia/edit/:id',
        props: true,
        name: 'OcorrenciaEdit',
        meta: {title: "Edição de Ocorrência"},
        component: () => import('@pages/AlunoOcorrencia/OcorrenciaEdit.vue')
    },
    {
        path: 'imagens',
        name: 'ImagemCarousel',
        meta: {title: "Imagens Site"},
        component: () => import('@pages/ImagemCarousel/ImagemCarousel.vue')
    },
    {
        path: 'noticia',
        name: 'Noticia',
        meta: {title: "Notícia"},
        component: () => import('@pages/Noticia/Noticia.vue')
    },
    {
        path: 'noticia/create',
        name: 'NoticiaCreate',
        meta: {title: "Cadastro de Notícia"},
        component: () => import('@pages/Noticia/NoticiaCreate.vue')
    },
    {
        path: 'noticia/edit/:id',
        props: true,
        name: 'NoticiaEdit',
        meta: {title: "Edição de Notícia"},
        component: () => import('@pages/Noticia/NoticiaEdit.vue')
    },
    {
        path: 'historia',
        name: 'Historia',
        meta: {title: "História EEMAN"},
        component: () => import('@pages/Historia/Historia.vue')
    },
    {
        path: 'curso-site',
        name: 'CursoSite',
        meta: {title: "Cursos EEMAN"},
        component: () => import('@pages/CursoSite/CursoSite.vue')
    },
    {
        path: 'curso-site/create',
        name: 'CursoSiteCreate',
        meta: {title: "Cadastro de Curso"},
        component: () => import('@pages/CursoSite/CursoSiteCreate.vue')
    },
    {
        path: 'curso-site/edit/:id',
        props: true,
        name: 'CursoSiteEdit',
        meta: {title: "Edição de Curso"},
        component: () => import('@pages/CursoSite/CursoSiteEdit.vue')
    },
    {
        path: 'modalidade-site',
        name: 'ModalidadeSite',
        meta: {title: "ModalidadeSite"},
        component: () => import('@pages/ModalidadeSite/ModalidadeSite.vue')
    },
    {
        path: 'modalidade-site/create',
        name: 'ModalidadeSiteCreate',
        meta: {title: "Cadastro de Modalidade"},
        component: () => import('@pages/ModalidadeSite/ModalidadeSiteCreate.vue')
    },
    {
        path: 'modalidade-site/edit/:id',
        props: true,
        name: 'ModalidadeSiteEdit',
        meta: {title: "Edição de Modalidade"},
        component: () => import('@pages/ModalidadeSite/ModalidadeSiteEdit.vue')
    },
    {
        path: 'depoimento',
        name: 'Depoimento',
        meta: {title: "Depoimento"},
        component: () => import('@pages/Depoimento/Depoimento.vue')
    },
    {
        path: 'depoimento/create',
        name: 'DepoimentoCreate',
        meta: {title: "Cadastro de Depoimento"},
        component: () => import('@pages/Depoimento/DepoimentoCreate.vue')
    },
    {
        path: 'depoimento/edit/:id',
        props: true,
        name: 'DepoimentoEdit',
        meta: {title: "Edição de Depoimento"},
        component: () => import('@pages/Depoimento/DepoimentoEdit.vue')
    },
    {
        path: 'configuracoes-site',
        name: 'ConfiguracaoSite',
        meta: {title: "Configurações do Site"},
        component: () => import('@pages/ConfiguracaoSite/ConfiguracaoSite.vue')
    },
    {
        path: 'sorteio',
        name: 'Sorteio',
        meta: {title: "Sorteio"},
        component: () => import('@pages/Sorteio/Sorteio.vue')
    },
]
