export default [
    {
        path: 'comprovanteRematricula/:aluno_id/:matricula_id',
        props: true,
        name: 'ComprovanteRematricula',
        component: () => import('@pages/Impressao/ComprovanteRematricula.vue')
    },
    {
        path: 'comprovanteNovaMatricula/:matricula_id/',
        props: true,
        name: 'ComprovanteNovaMatricula',
        component: () => import('@pages/Impressao/ComprovanteNovaMatricula.vue')
    },
    {
        path: 'ficha-matricula/:matricula_id/',
        props: true,
        name: 'FichaMatricula',
        component: () => import('@pages/Impressao/FichaMatricula.vue')
    },
    {
        path: 'carteira-estudante/:matricula_id/',
        props: true,
        name: 'CarteiraEstudante',
        component: () => import('@pages/Impressao/CarteiraEstudante.vue')
    },
    {
        path: 'declaracao-matricula/:matricula_id/',
        props: true,
        name: 'DeclaracaoMatricula',
        component: () => import('@pages/Impressao/DeclaracaoMatricula.vue')
    },

    {
        path: 'conta-receber/boleto/:parcela_id',
        props: true,
        name: 'EmitirBoleto',
        component: () => import('@pages/ContaReceber/EmitirBoleto.vue')
    },
    {
        path: 'matricula-online/boleto/:matricula_id',
        props: true,
        name: 'EmitirBoletoNovaMatricula',
        component: () => import('@pages/Impressao/BoletoNovaMatricula.vue')
    },
    {
        path: 'relatorio/matricula/turmas/:periodo_letivo_id/:curso_id/:periodo_id/:turma_id/:situacao',
        props: true,
        name: 'RelatorioTurmaPrint',
        component: () => import('@pages/Relatorio/Matriculas/Print/Turma.vue')
    },
    {
        path: 'relatorio/matricula/cursos/:periodo_letivo_id/:curso_id/:periodo_id/:situacao',
        props: true,
        name: 'RelatorioCursoPrint',
        component: () => import('@pages/Relatorio/Matriculas/Print/Curso.vue')
    },
    {
        path: 'relatorio/matricula/situacao/:periodo_letivo_id/:curso_id/:periodo_id/:situacao',
        props: true,
        name: 'RelatorioSituacaoPrint',
        component: () => import('@pages/Relatorio/Matriculas/Print/Situacao.vue')
    },
    {
        path: 'relatorio/matricula/professores/:periodo_letivo_id/:professor_id/:situacao',
        props: true,
        name: 'RelatorioProfessorPrint',
        component: () => import('@pages/Relatorio/Matriculas/Print/Professor.vue')
    },
    {
        path: 'relatorio/matricula/finalizacao/:periodo_letivo_id/:professor_id/:situacao',
        props: true,
        name: 'RelatorioFinalizacaoPrint',
        component: () => import('@pages/Relatorio/Matriculas/Print/Finalizacao.vue')
    },
    {
        path: 'diario/registro/:id',
        props: true,
        name: 'RegistroPrint',
        component: () => import('@pages/Impressao/Registro.vue')
    },
    {
        path: 'relatorio/frequencia/:periodo_letivo_id/:modalidade_id/:curso_id/:periodo_id/:turma_id/:mes',
        props: true,
        name: 'RelatorioFrequenciaPrint',
        component: () => import('@pages/Relatorio/FrequenciaPrint.vue')
    },
    {
        path: 'relatorio/salas',
        props: true,
        name: 'RelatorioSalasPrint',
        component: () => import('@pages/Relatorio/SalasPrint.vue')
    },
    {
        path: 'comprovanteNovaMatricula/tecnico/:aluno_id/:periodo_tecnico_id',
        props: true,
        name: 'ComprovanteNovaMatriculaTecnico',
        component: () => import('@pages/Impressao/ComprovanteNovaMatriculaTecnico.vue')
    },
    {
        path: 'ficha-matricula/tecnico/:matricula_id/',
        props: true,
        name: 'FichaMatriculaTecnico',
        component: () => import('@pages/Impressao/FichaMatriculaTecnico.vue')
    },
    {
        path: 'carteira-estudante/tecnico/:matricula_id/',
        props: true,
        name: 'CarteiraEstudanteTecnico',
        component: () => import('@pages/Impressao/CarteiraEstudanteTecnico.vue')
    },
    {
        path: 'registroatrasado/print/:id/',
        props: true,
        name: 'RegistroAtrasadosPrint',
        component: () => import('@pages/Relatorio/RegistroPrint.vue')
    },
    {
        path: 'relatorio/diarioclassetecnico/:periodo_letivo_id/:professor_id',
        props: true,
        name: 'RelatorioDiarioTecnicoPrint',
        component: () => import('@pages/MatriculaTecnico/RelatorioDiarioTecnicoPrint.vue')
    },
    {
        path: 'relatorio/diarioclasse/:periodo_letivo_id/:professor_id',
        props: true,
        name: 'RelatorioDiarioPrint',
        component: () => import('@pages/Relatorio/DiarioClassePrint.vue')
    },
    {
        path: 'relatorio/diarioclasseprofessor',
        props: true,
        name: 'RelatorioDiarioPrintProfessor',
        component: () => import('@pages/ProfessorPages/DiarioClassePrintProfessor.vue')
    },
    {
        path: 'relatorio/diarioclasseprofessortecnico',
        props: true,
        name: 'RelatorioDiarioPrintProfessorTecnico',
        component: () => import('@pages/ProfessorPages/DiarioClasseTecnicoPrintProfessor.vue')
    },
    {
        path: 'sorteio/print',
        name: 'sorteioPrint',
        component: () => import('@pages/Sorteio/SorteioPrint.vue')
    },

]
